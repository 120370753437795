import { Utils } from './utils';

const CustomEventNames = [
  'error',
  `documentLoading`,
  `documentLoadError`,
  `viewerStarted`,
  `modelLoading`,
  `modelLoaded`,
  `modelLoadError`,
];

/**
 * Creates a new ViewerService object to handle viewer interaction
 * @param {Object} Autodesk Forge Viewer Autodesk SDK
 * @param {Object} VueInstance Vue Instance
 */
const ViewerService = function (Autodesk, VueInstance) {
  // Autodesk Viewing object
  this.AutodeskViewing = Autodesk.Viewing;
  this.Autodesk = Autodesk;

  // Vue instance, store to be able to emit events
  this.VueInstance = VueInstance;

  // Events is an object storing the vue name of the event
  // and the function applied to Viewer3D, so it can be removed later on.
  this.Events = {};

  // Viewer3D instance
  this.Viewer3D = null;

  // Custom Extensions
  this.CustomExtensions;

  this.ViewerContainer;

  // Records the state of the ViewerService
  this.State = {
    initialized: false,
    headless: false,
    urns: [],
    svf: '',
    modelOptions: '',
    extensions: [],
    modelsLoaded: false,
  };

  this.getDatabase = function () {
    let viewer = this.Viewer3D;
    // eslint-disable-next-line no-unused-vars
    let instanceTree = viewer.model;
    return 0;
  };

  // If any event, try to add it to the Viewer instance
  let events = Object.keys(this.VueInstance.$listeners);
  this.SetEvents(events);
};

/**
 * Initialize the a Viewer instance
 * @param {String} containerId Id of the DOM element to host the viewer
 * @param accessToken
 * @param expire
 * @param headless
 * @param server
 * @param extensions
 * @param options
 * @param models
 */
ViewerService.prototype.LaunchViewer = async function (
  containerId,
  accessToken,
  expire,
  headless,
  server,
  extensions,
  options,
  models
) {
  if (!models || models.length <= 0) {
    return console.error('Empty model input');
  }
  // this.models = models

  this.State.modelsLoaded = false;
  let api;
  switch (server) {
    case 'US':
      api = 'derivativeV2';
      break;
    case 'EU':
      api = 'derivativeV2_EU';
      break;
    case 'EMEA':
      api = 'derivativeV2_EU';
      break;
    default:
      console.log('No server recognized');
  }

  let viewerOptions = {
    env: 'AutodeskProduction',
    api: api,

    // FIXME: get forgeAccessToken here from forge.store.js
    getAccessToken: function (onTokenReady) {
      onTokenReady(accessToken, expire);
    },
  };

  this.SetHeadless(headless);

  this.SetCustomExtensions(extensions);
  this.ExtensionOptions = options;

  this.AutodeskViewing.Initializer(viewerOptions, () => {
    this.ViewerContainer = document.getElementById(containerId);
    const view = new this.AutodeskViewing.AggregatedView();

    view.init(this.ViewerContainer);
    this.Viewer3D = view.viewer;
    this.State.initialized = true;
    this.LoadExtensions(extensions);

    const tasks = [];

    this.Viewer3D.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      (x) => {
        this.Viewer3D.setGroundShadow(false);
        this.Viewer3D.setGhosting(false);
        this.Viewer3D.setGroundReflection(false);
        this.Viewer3D.setQualityLevel(false, false);
        this.VueInstance.$emit('modelsRendered', x.model);
      }
    );

    view.waitForLoadDone().then((x) => {
      this.onModelLoaded(models);
    });

    models.forEach((md) => tasks.push(this.loadManifest(md)));

    Promise.all(tasks)
      .then((docs) =>
        Promise.resolve(
          docs.map((doc) => {
            const bubbles = doc
              .getRoot()
              .search({ type: 'geometry', role: '3d' });
            const bubble = bubbles[0];
            if (!bubble) return null;

            return bubble;
          })
        )
      )
      .then((bubbles) => view.setNodes(bubbles));
  });
};

ViewerService.prototype.LoadExtensions = function (extensions) {
  extensions.forEach((extension) => {
    let options = this.ExtensionOptions.find((x) => x.name === extension);
    if (options !== undefined) {
      options.vueInstance = this.VueInstance;
      this.Viewer3D.loadExtension(extension, options);
    } else {
      this.Viewer3D.loadExtension(extension);
    }
  });
};

/**
 * Sets the CustomExtensions object.
 * @param {Object} extensions Object where keys will be the extensions names and values should be functions to initialize new extensions.
 */
ViewerService.prototype.SetCustomExtensions = function (extensions) {
  // if (Object.values(extensions).some(value => typeof value != 'function')) {
  //   throw new Error('Extensions should be an object where its values are valid extension functions.')
  // }
  this.State.extensions = extensions;
  this.CustomExtensions = extensions;
};

/**
 * Determines if the ViewerService has any custom extensions
 * @return {Boolean} True if it has any custom extensions.
 */
ViewerService.prototype.HasCustomExtensions = function () {
  return (
    this.CustomExtensions != null &&
    Object.keys(this.CustomExtensions).length > 0
  );
};

/**
 * Sets, from the VueInstance event names, an object where to later on store
 * emmiters for the corresponding Forge events.
 * @param {String[]} events All Vue instance event names.
 */
ViewerService.prototype.SetEvents = function (events) {
  // ('Events being set.')
  this.Events = events
    .filter((name) => CustomEventNames.indexOf(name) == -1)
    .reduce((acc, name) => {
      acc[name] = null;
      return acc;
    }, {});
};

ViewerService.prototype.loadManifest = function (model) {
  let documentId = Utils.GetEncodedURN(model.urn);
  return new Promise((resolve, reject) => {
    const onDocumentLoadSuccess = (doc) => {
      doc.downloadAecModelData(() => resolve(doc));
    };
    this.AutodeskViewing.Document.load(
      documentId,
      onDocumentLoadSuccess,
      reject
    );
  });
};

/**
 * Register the View3D events according to those supplied by
 * the Vue component
 */
ViewerService.prototype.RegisterEvents = function () {
  let eventNames = Object.keys(this.Events);
  if (eventNames.length <= 0) return;

  for (let i = 0; i < eventNames.length; i++) {
    const vueEventName = eventNames[i];
    const viewerEventName = Utils.VueToViewer3DEvent(vueEventName);
    const eventType = this.AutodeskViewing[viewerEventName];

    if (eventType == null)
      throw new Error(`Event '${vueEventName}' doesn't exist on Forge Viewer`);

    // Avaliable event list: https://forge.autodesk.com/en/docs/viewer/v7/reference/Viewing/
    let emitterFunction = Utils.CreateEmitterFunction(
      this.VueInstance,
      vueEventName
    );
    this.Events[vueEventName] = emitterFunction;

    this.Viewer3D.addEventListener(eventType, emitterFunction);
  }
};
/**
 * Run in case you want to run headless Forge
 * @param {*} headless
 */
ViewerService.prototype.SetHeadless = function (headless) {
  let currentHeadless = this.State.headless;
  this.State.headless = headless;

  if (currentHeadless !== headless && this.Viewer3D != null) {
    this.Viewer3D.uninitialize();
    this.Viewer3D = null;

    if (this.State.svf) this.LoadModel(this.State.svf, this.State.modelOptions);
  }
};
/**
 * When document is loaded properly
 * @param {*} urn is the base64 URLsafe location of the model
 * @param {*} doc
 * @returns
 */
ViewerService.prototype.onDocumentLoadSuccess = function (urn, doc) {
  let geometries = doc.getRoot().search({ type: 'geometry' });
  if (geometries.length === 0) {
    Utils.EmitError(
      this.VueInstance,
      new Error('Document contains no geometries.')
    );
    return;
  }

  // Choose any of the available geometries
  const initGeom = geometries[0];

  // Load the chosen geometry
  let svf = doc.getViewablePath(geometries[0]);
  let modelOptions = {
    sharedPropertyDbPath: doc.getFullPath(doc.getRoot().findPropertyDbPath()),
  };

  this.LoadModel(doc, urn, initGeom, svf, modelOptions, this.State.extensions);
};
/**
 * Get viewer instance
 * @param {*} container
 * @param {*} configuration
 * @param {*} headless
 * @param {*} extensions
 * @returns viewer instaance
 */
ViewerService.prototype.GetViewerInstance = function (
  container,
  configuration,
  headless,
  extensions
) {
  // let data = this.GetViewer3DConfig()
  if (headless === true)
    return new this.AutodeskViewing.Viewer3D(this.ViewerContainer, config);
  let config = {
    extensions: extensions,
  };

  return new this.AutodeskViewing.GuiViewer3D(this.ViewerContainer, config);
};

/**
 * Search in the model with seperate API Call, highlight objects4
 * @param {*} doc documentID
 * @param {string} urn is the base64 URLsafe location of the model
 * @param {*} initGeom
 * @param {*} svfURL
 * @param {*} modelOptions
 * @param {*} extensions
 */
// eslint-disable-next-line no-unused-vars
ViewerService.prototype.LoadModel = async function (
  doc,
  urn,
  initGeom,
  svfURL,
  modelOptions,
  extensions
) {
  // load a node in the fetched document
  this.Viewer3D.loadDocumentNode(doc.getRoot().lmvDocument, initGeom, {
    keepCurrentModels: true,
  });

  this.RegisterEvents();

  // Emitting Viewer3D Started event
  this.VueInstance.$emit('viewerStarted', this.Viewer3D);

  this.VueInstance.$emit('modelLoading');
  this.State.svf = svfURL;
  this.State.modelOptions = modelOptions;
};

ViewerService.prototype.onDocumentLoadError = function (errorCode) {
  if (this.VueInstance.$listeners['documentLoadError'])
    this.VueInstance.$emit('documentLoadError', errorCode);
  else
    Utils.EmitError(
      this.VueInstance,
      new Error('Failed to load document. Error Code: ' + errorCode)
    );
};

ViewerService.prototype.onModelLoaded = function (item) {
  this.VueInstance.$emit('modelLoaded', item);
  this.State.modelsLoaded = true;
};

ViewerService.prototype.onModelLoadError = function (errorCode) {
  if (this.VueInstance.$listeners['modelLoadError'])
    this.VueInstance.$emit('modelLoadError', errorCode);
  else
    Utils.EmitError(
      this.VueInstance,
      new Error('Failed to load model. Error Code: ' + errorCode)
    );
};

ViewerService.prototype.onGeometryLoaded = function (item) {
  console.log(item);
};

/**
 * Search in the model with seperate API Call, highlight objects4
 * @param {string} searchStr search phrase in which you want to search through the complete model
 */
ViewerService.prototype.highlightElementInObject = function (searchStr) {
  let viewer = this.Viewer3D;
  if (searchStr.length === 0) {
    return;
  }
  viewer.clearThemingColors();
  viewer.search(searchStr, searchCallback, searchErrorCallback);

  function searchCallback(ids) {
    viewer.select(ids);
  }

  function searchErrorCallback(string) {
    `${string} not found!`;
  }
};

/**
 * Search in the model with seperate API Call
 * @param {string} searchStr search phrase in which you want to search through the complete model
 */
ViewerService.prototype.search = function (searchStr) {
  let viewer = this.Viewer3D;
  if (!searchStr) {
    return;
  }
  viewer.clearSelection();
  viewer.search(searchStr, searchCallback, searchErrorCallback);

  function searchCallback(ids) {
    viewer.isolate(ids);
    viewer.fitToView(ids);
  }

  function searchErrorCallback(string) {
    `${string} not found!`;
  }
};

/**
 * Completely clear search
 */
ViewerService.prototype.clearSearch = function () {
  let viewer = this.Viewer3D;
  viewer.showAll();
  viewer.fitToView();
  viewer.clearThemingColors();
  viewer.clearSelection();
};

/**
 * Completely clear view without
 */
ViewerService.prototype.clearViewWithoutFit = function () {
  let viewer = this.Viewer3D;

  viewer.clearSelection();
  viewer.showAll();
  let allModels = viewer.impl.modelQueue().getModels().concat();
  allModels.forEach((model) => viewer.clearThemingColors(model));
};

/**
 * Go back to central place in model, keep Theming colors
 */
ViewerService.prototype.displayAllWithTheming = function () {
  let viewer = this.Viewer3D;
  viewer.showAll();
  viewer.fitToView();
  viewer.clearSelection();
};

/**
 * Resize the forge viewer
 */
ViewerService.prototype.resizeView = function () {
  this.Viewer3D.resize();
};

/**
 * Disable ghosting in viewer
 */
ViewerService.prototype.disableGhosting = function () {
  this.Viewer3D.setGhosting(false);
};

/**
 * Set the view to an box angle. i.e. 'front left top'
 */
ViewerService.prototype.setViewTo = function (side) {
  let viewcuiext = this.Viewer3D.getExtension('Autodesk.ViewCubeUi');
  viewcuiext.setViewCube(side);
};

export { ViewerService };
