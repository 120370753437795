export const ForgeExtension = {
  BASIC_SKELETON: 'BasicSkeleton',
  CAMERA_ROTATION: 'CameraRotation',
  CUSTOM_PROPERTIES_EXTENSION: 'CustomPropertiesExtension',
  DRAW_TOOL_EXTENSION: 'DrawToolExtension',
  GOOGLE_MAPS_LOCATOR: 'GoogleMapsLocator',
  NESTED_VIEWER_EXTENSION: 'NestedViewerExtension',
  POTREE_EXTENSION: 'PotreeExtension',
  TAB_SELECTION_EXTENSION: 'TabSelectionExtension',
  TRANSFORMATION_EXTENSION: 'TransformationExtension',
  XLS_EXTENSION: 'XLSExtension',
  AUTODESK_FUSION360_ANIMATION: 'Autodesk.Fusion360.Animation',
  AUTODESK_SECTION: 'Autodesk.Section',
  AUTODESK_DEFAULT_TOOLS_NAV_TOOLS: 'Autodesk.DefaultTools.NavTools',
  AUTODESK_HYPERLINK: 'Autodesk.Hyperlink',
  AUTODESK_MEASURE: 'Autodesk.Measure',
  AUTODESK_VIEWING_FUSION_ORBIT: 'Autodesk.Viewing.FusionOrbit',
  AUTODESK_BIM_WALK: 'Autodesk.BimWalk',
  AUTODESK_GO_HOME: 'Autodesk.GoHome',
  AUTODESK_EXPLODE: 'Autodesk.Explode',
  AUTODESK_FULL_SCREEN: 'Autodesk.FullScreen',
  AUTODESK_LAYER_MANAGER: 'Autodesk.LayerManager',
  AUTODESK_MODEL_STRUCTURE: 'Autodesk.ModelStructure',
  AUTODESK_PROPERTIES_MANAGER: 'Autodesk.PropertiesManager',
  AUTODESK_VIEWER_SETTINGS: 'Autodesk.ViewerSettings',
  AUTODESK_VIEWING_WIREFRAMES: 'Autodesk.Viewing.Wireframes',
  AUTODESK_VIEWING_ZOOM_WINDOW: 'Autodesk.Viewing.ZoomWindow',
  AUTODESK_SPLIT_SCREEN: 'Autodesk.SplitScreen',
  AUTODESK_PDF: 'Autodesk.PDF',
  AUTODESK_DOCUMENT_BROWSER: 'Autodesk.DocumentBrowser',
  AUTODESK_GEOLOCATION: 'Autodesk.Geolocation',
  AUTODESK_CROSS_FADE_EFFECTS: 'Autodesk.CrossFadeEffects',
  AUTODESK_EDIT2_D: 'Autodesk.Edit2D',
  AUTODESK_VIEW_CUBE_UI: 'Autodesk.ViewCubeUi',
  AUTODESK_BIM360_MINIMAP: 'Autodesk.BIM360.Minimap',
  AUTODESK_BIM360_GESTURE_DOCUMENT_NAVIGATION:
    'Autodesk.BIM360.GestureDocumentNavigation',
  AUTODESK_BIM360_ROLL_CAMERA: 'Autodesk.BIM360.RollCamera',
  AUTODESK_VIEWING_SCENE_BUILDER: 'Autodesk.Viewing.SceneBuilder',
  AUTODESK_SNAPPING: 'Autodesk.Snapping',
  AUTODESK_VIEWING_POPOUT: 'Autodesk.Viewing.Popout',
  AUTODESK_VISUAL_CLUSTERS: 'Autodesk.VisualClusters',
  AUTODESK_AEC_LEVELS_EXTENSION: 'Autodesk.AEC.LevelsExtension',
  ANT_RFIS_EXTENSION: 'RfisExtension',
};
